<template>
  <div>
    <div class="mb-1 pt-25 d-flex align-items-center justify-content-between">
      <h6 class="m-0 text-ellipsis">
        {{ $t('pages.plots.list.crops.title') }}
      </h6>
    </div>
    <b-list-group
      class="mb-2"
    >
      <template v-if="has">
        <b-list-group-item
          v-for="item in crops"
          :key="item.id"
          class="d-flex justify-content-between align-items-center"
          @action="goToOne(item.id)"
        >
          <span class="text-ellipsis">
            <span
              v-if="item.emoji !== undefined && item.emoji !== null && item.emoji.length>0"
              style="font-size: 1.3em"
            >{{ item.emoji }} &nbsp;&nbsp;</span> {{ item.name }}
          </span>
          <span class="col-2 d-flex justify-content-end m-0 p-0">
            <b-list-group-item-button
              disable
              color="primary"
            >
              <i class="fa-solid fa-angle-right" />
            </b-list-group-item-button>
          </span>
        </b-list-group-item>
      </template>
      <template v-else>
        <b-list-group-item
          class="d-flex justify-content-between align-items-center"
        >
          <span class="text-ellipsis text-black-50">
            {{ $t('pages.plots.list.crops.no-crops') }}
          </span>
        </b-list-group-item>
      </template>
    </b-list-group>
  </div>
</template>

<script>
import ListGroupItem from '@core/components/list-group/ListGroupItem.vue'
import ListGroupItemButton from '@core/components/list-group/ListGroupItemButton.vue'
import { BListGroup } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { NAME_CROP_ONE } from '@/router/routes/crops'

export default {
  components: {
    BListGroup,
    'b-list-group-item': ListGroupItem,
    'b-list-group-item-button': ListGroupItemButton,
  },
  props: {
    plot: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      // ui

      // data
    }
  },
  computed: {
    ...mapGetters({
      list: 'crops/getList',
    }),
    has() {
      return this.crops !== undefined && this.crops !== null && this.crops.length >= 1
    },
    crops() {
      return this.list.filter(c => this.plot.crops_id.includes(c.id))
    },
  },
  watch: {},
  methods: {
    goToOne(id) {
      this.$router.push({
        name: NAME_CROP_ONE,
        params: {
          id,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../../@core/scss/base/bootstrap-extended/_include';
@import '../../../../@core/scss/base/components/_include';

</style>
